﻿
import { MDCMenu } from '@material/menu';
import { MDCSelect } from '@material/select';
import { MDCDialog } from '@material/dialog';
import { MDCTextField } from '@material/textfield';
import { MDCList } from '@material/list';
import { MDCMenuSurface } from '@material/menu-surface';
import { MDCSnackbar } from '@material/snackbar';
import { MDCDataTable } from '@material/data-table';
import { MDCTabBar } from '@material/tab-bar';

window.callback = {
   
    InitializeMenu: function (anchorID) {
        var anchorElement = document.getElementById(anchorID);
        var listElement = anchorElement.getElementsByClassName("mdc-menu")[0];
        var menu = new MDCMenu(listElement);

        var sur = new MDCMenuSurface(listElement);
        var List1 = new MDCList(listElement);

        anchorElement.addEventListener("click", () => {
            if (listElement.classList.contains("mdc-menu-surface--open"))
                menu.open = false;
            else
                menu.open = true;
        })
    },

    InitializeMenuInTheDiv: function (ID) {
        // Initialize menu
        var element = document.getElementById(ID);
        for (const anchorEle of element.querySelectorAll('.mdc-menu-surface--anchor')) {
            let anchorElement = anchorEle
            let listElement = anchorElement.getElementsByClassName("mdc-menu")[0];
            let menu = new MDCMenu(listElement);

            let sur = new MDCMenuSurface(listElement);
            let List1 = new MDCList(listElement);

            if (listElement.getAttribute('listener') != 'true') {
                listElement.setAttribute('listener', 'true');
                anchorElement.addEventListener("click", () => {
                    if (listElement.classList.contains("mdc-menu-surface--open"))
                        menu.open = false;
                    else
                        menu.open = true;
                })
            }
        }
    },


    Datatables: function () {
        for (const element of document.querySelectorAll('.mdc-data-table')) {
            var datatable = new MDCDataTable(element);
            const select = new MDCSelect(element.querySelector('.mdc-select'));           
        }
    },

    InitializeDatatable: function (ID) {
        const element = document.getElementById(ID);
        var datatable = new MDCDataTable(element);
        var select = new MDCSelect(element.querySelector('.mdc-select'));
    },

    openMenuList: function (ListID) {
        const menu = new MDCMenu(document.getElementById(ListID));
        menu.open = true;
    },

    //To remove class from the element
    removeClassJS: function (element, classname) {
        var ele = document.querySelector(element);
        if (ele != null)
            ele.classList.remove(classname);
    },

    //To add class to the element
    addClassJS: function (element, classname) {
        var ele = document.querySelector(element);
        if (ele != null)
            ele.classList.add(classname);
    },

    SetSelectedValueAutoComplete: function (ListId, InputId, LabelID) {
        document.getElementById(LabelID).classList.add("mdc-text-field--label-floating");
        document.getElementById(InputId).value = document.getElementById(ListId).getElementsByClassName("mdc-list-item--selected")[0].innerText;

    },

    OpenLandingPageDialog: function (dialogID) {
        const dialog = new MDCDialog(document.querySelector(dialogID));
        dialog.open();
    },

    CloseLandingPageDialog: function (dialogID) {
        const dialog = new MDCDialog(document.querySelector(dialogID));
        if (dialog != null && dialog.root.classList.contains("mdc-dialog--open")) {
            dialog.foundation.dialogOpen = true;
            dialog.close();
        }
    },

    OpenDialog: function (dialogID) {
        var dialogEle = document.querySelector(dialogID);
        //var scrollTop = document.querySelector("#main-content").scrollTop + "px";
        //dialogEle.style.top = scrollTop;
        //dialogEle.getElementsByClassName("mdc-dialog__scrim")[0].style.top = scrollTop;
        if (dialogEle != null) {
            const dialog = new MDCDialog(dialogEle);
            dialog.open();
        }
    },

    OpenFormDialog: function (dialogID) {
        var dialogEle = document.querySelector(dialogID);
        if (dialogEle != null) {
            if (document.querySelector("#main-content") != null) {
                var scrollTop = document.querySelector("#main-content").scrollTop + "px";
                dialogEle.style.top = scrollTop;
                dialogEle.getElementsByClassName("mdc-dialog__scrim")[0].style.top = scrollTop;
            }
            const dialog = new MDCDialog(dialogEle);
            dialog.open();
        }
    },


    CloseDialog: function (dialogID) {
        var ele = document.querySelector(dialogID);
        if (ele != null) {
            const dialog = new MDCDialog(ele);
            if (dialog.root.classList.contains("mdc-dialog--open")) {
                dialog.foundation.dialogOpen = true;
                dialog.close();
            }
        }
    },


    // Functions for Text fields
    TextFieldObj: {},

    InitializeTextField: function (id, autofocus) {
        var element = document.getElementById(id);
        if (this.TextFieldObj.hasOwnProperty(id)) {
            this.DisposeTextField(id);
            var labelElement = element.getElementsByClassName("mdc-floating-label");
            if (labelElement.length != 0) {
                labelElement[0].classList.remove("mdc-floating-label--float-above");
                element.classList.remove("mdc-text-field--label-floating");
            }
            
        }
        var txtField = new MDCTextField.attachTo(element);
        txtField.layout();
        this.TextFieldObj[id] = txtField;
        if (autofocus) {
            this.SetFocus(id);
        }

        //if (isEditMode)
        //    CheckNullAndAddWarningClass();

        //function CheckNullAndAddWarningClass() {
        //    var inputValue = element.getElementsByClassName("mdc-text-field__input")[0].value;
        //    if (inputValue == null || inputValue == "") {
        //        element.classList.add("mdc-text-field--warning");
        //    }
        //    else {
        //        element.classList.remove("mdc-text-field--warning");
        //    }
        //}

        element.getElementsByClassName("mdc-text-field__input")[0].addEventListener("focus", () => {
            this.SetFocus(id);
        })

        
    },

    ValidationOfNumericFields: function (id, AddThousandSeperator) {
        var element = document.getElementById(id);
        if (element != null) {
            var inputField = element.getElementsByClassName("mdc-text-field__input")[0];
            if (AddThousandSeperator && inputField.value != null) {
                var numValue = inputField.value.replace(/,/g, '');
                var parts = numValue.toString().split('.');
                parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                inputField.value = parts.join('.');
            }
            inputField.addEventListener("focus", (e) => {
                e.target.value = e.target.value.replace(/,/g, '');
            });

            inputField.addEventListener("keydown", (e) => {
                //ref: https://www.freecodecamp.org/news/javascript-keycode-list-keypress-event-key-codes/
                // Allow copy and paste in numeric fields
                if ((e.keyCode == 67 || e.keyCode == 86 || e.keyCode == 88) && (e.ctrlKey || e.metaKey)) {
                    return;
                }
                if (["Backspace", "Shift", "Tab", ".", "-", "Delete", "ArrowRight", "ArrowLeft"].includes(e.key) == false) {
                    if (isNaN(parseFloat(e.key))) {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                }
                else {
                    if (e.target.getAttribute('data-decimals') == 0) {
                        if (e.key === '.') {
                            e.preventDefault();
                            e.stopPropagation();
                        }
                    }
                    else
                        // prevent from second decimal point 
                        if (e.key == "." && e.target.value.match(/[.]/g) != null &&
                            e.target.value.match(/[.]/g).length == 1) {
                            e.preventDefault();
                            e.stopPropagation();
                        }
                }
            });

            if (AddThousandSeperator) {
                inputField.addEventListener("blur", (e) => {
                    var numValue = inputField.value.replace(/,/g, '');
                    var parts = numValue.toString().split('.');
                    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    inputField.value = parts.join('.');
                });
            }

            inputField.addEventListener("input", (e) => {
                
                //var numValue = e.target.value.replace(/[,]/g, '');
                //let caretPosition = e.target.selectionStart;
                ////remove any "-" entered except starting position
                //if (numValue.match(/(?!^)-/g) != null) {
                //    numValue = numValue.replace(/(?!^)-/g, '');
                //    return;
                //}
                //else {
                //    if (Math.abs(numValue).toString().length % 3 == 1)
                //        caretPosition = caretPosition + 1;
                //}
                if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(0, e.target.maxLength);
                if (e.target.value.split('.')[1] > e.target.getAttribute('data-decimals') * 1) {
                    e.target.value = Math.floor(e.target.value * Math.pow(10,
                        e.target.getAttribute('data-decimals') * 1)) / Math.pow(10, e.target.getAttribute('data-decimals') * 1);
                }

                //if (AddThousandSeperator) {
                //    numValue = numValue.toString().split(".");
                //    numValue[0] = numValue[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                //    e.target.value = numValue.join(".");

                //    e.target.selectionStart = caretPosition;
                //    e.target.selectionEnd = caretPosition;
                //}
                //else {
//                    e.target.value = numValue;
                //}
            })
        }
    },

    SetFocus: function (id) {
        const element = document.getElementById(id);
        if (element != null && element != document.activeElement) {
            //element.setAttribute('tabindex', '0');
            element.classList.remove("mdc-text-field--warning");
            element.classList.add("mdc-text-field--focused");
            this.TextFieldObj[id].focus();
            this.TextFieldObj[id].layout();
        }
       
    },

    RefreshTextField: function (id) {
        //var element = document.getElementById(id);
        if (this.TextFieldObj.hasOwnProperty(id)) {
            this.TextFieldObj[id].layout();
        }
    },

    DisposeTextField: function (id) {
        if (this.TextFieldObj.hasOwnProperty(id)) {

            this.TextFieldObj[id].destroy();
            delete this.TextFieldObj[id];
        }
    },


    SetDefaultValueOfSelect: function (id, initialValue) {
        if (initialValue == "") {
        }
        else {
            var index = 0;
            var element = document.getElementById(id);
            element.getElementsByClassName("mdc-list")[0]
                .querySelectorAll("li").forEach((el, ind) => {
                    if (el.getAttribute('data-value') == initialValue)
                        index = ind;
            });
            element.select.setSelectedIndex(index);
            element.classList.remove("mdc-select--warning");
            element.select.layout();
        }
    },

    InitializeSelect: function (id, isEditMode, AutoFocus, addOrangeBorder, dotnetHelper) {
        var element = document.getElementById(id);
        element.select = new MDCSelect(element);
        element.classList.remove("mdc-select--warning");
        element.select.layout();

        //set focus on select
        if (AutoFocus) {
            element.select.selectAnchor.focus();
            element.select.layout();
        }

        element.getElementsByClassName("mdc-select__anchor")[0].addEventListener("focus", () => {
            element.classList.remove("mdc-select--warning");
            element.select.layout();
            element.select.selectAnchor.focus();
        })

        element.getElementsByClassName("mdc-select__anchor")[0].addEventListener("focusout", () => {
            if (!element.classList.contains("mdc-select--activated")) {
                CheckNullAndAddWarningClass();
            }
        })

        element.select.listen('MDCSelect:change', () => {
            dotnetHelper.invokeMethodAsync("SelectedItem", parseInt(element.select.value));
        });

        function CheckNullAndAddWarningClass() {
            if (addOrangeBorder) {
                var selectedElement = element.getElementsByClassName("mdc-list-item--selected");
                if (selectedElement.length > 0) {
                    var inputValue = selectedElement[0].getAttribute("data-value");
                    if (inputValue == null || inputValue == "") {
                        element.classList.add("mdc-select--warning");
                    }
                }
                else {
                    element.classList.add("mdc-select--warning");
                }
            }
        }

        if (isEditMode && !element.classList.contains("mdc-select--focused")) {
            CheckNullAndAddWarningClass();
        }

        element.select.foundation.handleMenuClosing = function () {
                CheckNullAndAddWarningClass();
        }
    },

    RefreshSelect: function (id, AutoFocus) {
        var element = document.getElementById(id);
        element.select.destroy();
        element.select = new MDCSelect(element);
        element.classList.remove("mdc-select--warning");
        if (AutoFocus) {
            element.select.selectAnchor.focus();
        }
        element.select.layout();
    },

    SetFocusOnSelect: function (id) {
        document.getElementById(id).getElementsByClassName("mdc-select__anchor")[0].focus();
    },
   
    DisposeSelect: function (id) {
        var element = document.getElementById(id);
        element.select.destroy();
    },
    
    panelToggle: async function (btnID, ConfluenceID="DEFAULT" ) {
        let rightNavTool = document.getElementById('rightNav');
        let ctrListPanels = ['maximized', 'full'];
        let panelNotes = document.getElementById('notesPanel-container');
        let panelHelp = document.getElementById('helpPanel-container');
        let btnNotes = document.getElementById('notesPanel');
        let btnHelp = document.getElementById('helpPanel');

        function handleNotesToolbarToggle(toggleClass, panelActive, panelID) {
            let panel = document.getElementById(panelID);

            if (rightNavTool.classList.contains(`${toggleClass}`) && panelNotes.classList.contains('hidden')) {
                btnHelp.classList.remove(`${panelActive}`);
                panel.classList.add(`${panelActive}`);
                panelHelp.classList.add('hidden');
                panelNotes.classList.remove('hidden');
            }
            else if (rightNavTool.classList.contains(`${toggleClass}`) && !ctrListPanels.some(listClass => rightNavTool.classList.contains(listClass))) {
                rightNavTool.classList.remove(`${toggleClass}`);
                panel.classList.remove(`${panelActive}`);
                panelNotes.classList.add('hidden');
            }
            else {
                rightNavTool.classList.add(`${toggleClass}`);
                panel.classList.add(`${panelActive}`);
                panelNotes.classList.remove('hidden');
            }
        }

        function handleHelpToolbarToggle(toggleClass, panelActive, panelID, ConfluenceID) {
            let panel = document.getElementById(panelID);


            if (rightNavTool.classList.contains(`${toggleClass}`) && panelHelp.classList.contains('hidden')) {
                btnNotes.classList.remove(`${panelActive}`);
                panel.classList.add(`${panelActive}`);
                panelNotes.classList.add('hidden');
                panelHelp.classList.remove('hidden');
            }
            else if (rightNavTool.classList.contains(`${toggleClass}`) && !ctrListPanels.some(listClass => rightNavTool.classList.contains(listClass))) {
                if(ConfluenceID === "DEFAULT"){
                    rightNavTool.classList.remove(`${toggleClass}`);
                    panel.classList.remove(`${panelActive}`);
                    panelHelp.classList.add('hidden');
                }
            }
            else {
                rightNavTool.classList.add(`${toggleClass}`);
                panel.classList.add(`${panelActive}`);
                panelHelp.classList.remove('hidden');
            }
        }

        if (btnID === 'notesPanel') {
            handleNotesToolbarToggle('toggleToolbar', 'panel-active', 'notesPanel');
        }
        else if (btnID === 'helpPanel') {
           handleHelpToolbarToggle('toggleToolbar', 'panel-active', 'helpPanel', ConfluenceID);
        }
    },

    notesControls: async function () {
        let controls = document.querySelectorAll('.panel-icons .material-icons');
        let rightNavToolPanel = document.getElementById('rightNav');
        let ctrList = ['minimized', 'maximized', 'full'];

        function ctrListControl(ctr) {
            rightNavToolPanel.classList.add(ctr);
        }
        controls.forEach(function (control) {
            let controlID = control.id;

            control.addEventListener('click', () => {
                rightNavToolPanel.classList.remove(...ctrList);

                if (controlID === 'btnIconMin') {
                    ctrListControl('minimized');
                }
                if (controlID === 'btnIconMax') {
                    ctrListControl('maximized');
                }
                if (controlID === 'btnIconFull') {
                    ctrListControl('full');
                }
            })
        });
    },

    //tabToggle: async function () {

        /** tab material default
         * @params mdc-tab--active, mdc-tab-indicator--active */
        
        //let tabs = document.querySelectorAll('.mdc-tab');

        //tabs.forEach(function (tab) {

        //    function removeActives(elemActive, childIndicatorActive) {
        //        let otherActive = document.querySelector(`.${elemActive}`);
        //        let otherChildIndicatorActive = otherActive.querySelector(`.${childIndicatorActive}`);

        //        if (otherActive) {
        //            otherActive.classList.remove(`${elemActive}`);
        //            otherActive.setAttribute('aria-selected', false);
        //            otherChildIndicatorActive.classList.remove(`${childIndicatorActive}`);
        //        }
        //    }

        //    tab.addEventListener('click', () => {
        //        removeActives('mdc-tab--active', 'mdc-tab-indicator--active');
        //        tab.classList.add('mdc-tab--active');
        //        tab.setAttribute('aria-selected', true);
        //        tab.querySelector('.mdc-tab-indicator').classList.add('mdc-tab-indicator--active');
        //    });
        //});

    //},

    //dataTableSort: function (rowData) {
    //    console.log(rowData);
        //let isNaNorNot = (target) => target === '' || isNaN(target);
        //let mdcCell = (row, colStart) => row.children[colStart].innerText || row.children[colStart].textContent;

        //let mdcCells = (firstCell, secondCell) => {
        //    return isNaNorNot(firstCell) || isNaNorNot(secondCell) ? firstCell.toString().localeCompare(secondCell) : firstCell - secondCell;
        //};

        //let sortTable = (colStart, upward) => (firstEl, secondEl) => {
        //    let firstCell = mdcCell(firstEl, colStart);
        //    let secondCell = mdcCell(secondEl, colStart);

        //    return upward ? mdcCells(firstCell, secondCell) : mdcCells(secondCell, firstCell);
        //};

        //let sortedTh = document.querySelectorAll('.mdc-data-table__header-cell--with-sort');

        //sortedTh.forEach((sortCol) =>
        //    sortCol.addEventListener('click', () => {
        //        let mdcTable = sortCol.closest('.mdc-data-table__table');
        //        let mdcTBody = mdcTable.querySelector('tbody');
        //        let colStart = Array.from(sortCol.parentNode.children).indexOf(sortCol);
        //        let sort = sortTable(colStart, (this.upward = !this.upward));
        //        Array.from(mdcTBody.querySelectorAll("tr")).sort(sort).forEach((tr) => mdcTBody.appendChild(tr));
        //    })
        //);

    /*},*/

   
    //AutoComplete TextFields
    AutocompleteMenuObj: {},

    InitializeAutoCompleteClass: function (id, listID, dotnetHelper) {
        this.InitializeTextField(id, false);
        var txtFieldElement = document.getElementById(id);
        var txtField = this.TextFieldObj[id];
        const ele = document.getElementById(listID);

        if (this.AutocompleteMenuObj.hasOwnProperty(listID)) {
            if (this.AutocompleteMenuObj.hasOwnProperty(listID)) {
                this.AutocompleteMenuObj[listID].destroy();
                delete this.AutocompleteMenuObj[listID];
            }
        }
        const menuList = new MDCMenu(ele);
        menuList.layout();
        this.AutocompleteMenuObj[listID] = menuList;

        txtFieldElement.addEventListener("click", (e) => {
            menuList.close;
            menuList.menuSurface.foundation.isSurfaceOpen = false;
            menuList.open = true;
            e.target.selectionStart = 0;
            e.target.selectionEnd = 1000;
            //sroll to selected dropdown index
            dotnetHelper.invokeMethodAsync("GetIndexOfSelectedValue").then(x => {
                if (x != -1) {
                    var height = 24;
                    if (ele.querySelectorAll('li').length != 0)
                        height = ele.querySelectorAll('li')[0].offsetHeight
                    ele.scroll(0, height * (x - 2));
                }
                else {
                    ele.scroll(0, 0);
                }
            })
        })
        
        txtFieldElement.addEventListener("keydown", (e) => {
            if (e.which != 9) {
                menuList.close;
                menuList.menuSurface.foundation.isSurfaceOpen = false;
                menuList.open = true;
            }
            //on down arrow key move to dropdown list
            if (e.keyCode == 40) {
                e.preventDefault();
                if (ele.getElementsByClassName("mdc-list-item--selected").length == 0) {
                    ele.querySelectorAll('li')[0].focus();
                }
                else {
                    ele.getElementsByClassName("mdc-list-item--selected")[0].focus();
                }
            }
        })
        //document.getElementById(id).parentElement.addEventListener("focusin", () => {
        //    txtField.foundation.shouldFloat = true;
        //    txtField.layout();
        //});
    },

    SetFocusOnAutocompleteList: function (id) {
        //document.getElementById(id).selectAnchor.focus();
        this.AutocompleteMenuObj[id].foundation.setDefaultFocusState();

    },

    openAuotocompleteMenuList: function (listID) {
        if (this.AutocompleteMenuObj.hasOwnProperty(listID)) {
            this.AutocompleteMenuObj[listID].close;
            this.AutocompleteMenuObj[listID].menuSurface.foundation.isSurfaceOpen = false;
            this.AutocompleteMenuObj[listID].open = true;
        }
    },

    InitializeSnackBar: function (id) {
        var snackbar = new MDCSnackbar(document.getElementById(id));
    },

    ScrollToBottomOfDiv: function (id) {
        var ele = document.getElementById(id);
        if (ele != null) {
            var objDiv = ele.getElementsByClassName("mdc-dialog__content")[0];
            if (objDiv != null)
                objDiv.scrollTop = objDiv.scrollHeight;
        }
    },

    ScrollToTopOfDiv: function (id) {
        document.getElementById(id).scrollTo({ top: 0, behavior: 'smooth' });
    },

    HideElement: function (id) {
        document.getElementById(id).style.display = "none";
    },

    ScrollIntoViewByID: function (id) {
        document.getElementById(id).scrollIntoView({ behavior: 'smooth' })
    }
}